import React from 'react';
import useSWR from 'swr';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useUser } from '../user/UserProvider';
import Spinner from '../common/spinner/Spinner';
import { useIntl } from 'react-intl';
import { useApi } from '../../api/apiProvider';
import { getStatus, getStatusText } from './meldingHelper';
import FaIcon from '../common/icons/FaIcon';
import Translate from '../locale/Translate';
import MeldingError from './MeldingError';
import FormatDate from '../locale/FormatDate';
import { useParams } from 'react-router';

type Meldingsdetaljer = {
  meldingId: string;
  lopeNummer: string;
  adresse: string;
  meldingstype: { meldingstypeId: string; navn: string; beskrivelse: string };
  meldingsStatus: { id: number; foreldreId: number; navn: string; tekstmal: string };
  opprettetTid: string;
  beskrivelse: string;
  bildeIds: Array<string>;
  latitude: number;
  longitude: number;
};

const Meldingsdetaljer: React.FC<{ fulgte?: boolean }> = ({ fulgte }) => {
  const { urls, api } = useApi();
  const { user } = useUser();
  const { formatMessage } = useIntl();
  const { meldingId } = useParams();

  const url = fulgte ? urls.fulgtmelding(meldingId) : urls.getPublikumMelding(user.id, meldingId);

  const getEgenMelding = async (): Promise<Meldingsdetaljer> => {
    const response = await api.get(url);
    return response?.data?.result;
  };

  const getFulgtMelding = async (): Promise<Meldingsdetaljer> => {
    const response = await api.get(url);
    return response?.data?.result?.meldinger[0];
  };

  const getMelding = async (): Promise<Meldingsdetaljer> => {
    const detaljer = fulgte ? getFulgtMelding() : getEgenMelding();
    return detaljer;
  };

  const { data: melding, error } = useSWR(url, getMelding);

  return (
    <>
      {!melding && !error && <Spinner />}
      {melding && (
        <div className="text-5 meldingsdetaljer ">
          <div className="kart">
            <MapContainer
              center={{ lat: melding.latitude, lng: melding.longitude }}
              zoom={17}
              maxZoom={17}
              minZoom={17}
              zoomControl={false}
              dragging={false}
              doubleClickZoom={false}
              tap={false}
              touchZoom={false}
              scrollWheelZoom={false}
            >
              <TileLayer
                url="https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}"
                attribution="&copy; <a href='http://www.kartverket.no'>Kartverket</a>"
              />
              <Marker
                keyboard={false}
                position={{ lat: melding.latitude, lng: melding.longitude }}
                icon={L.divIcon({
                  className: 'custom-div-icon',
                  html: `<div class='marker-pin'></div><i class="osg-icon-exclamation-mark-circle" style="height: 10px"></i>`,
                  iconSize: [30, 42],
                  iconAnchor: [15, 42],
                })}
              />
            </MapContainer>
          </div>
          <div className="info">
            <h4 className="heading-4 py-2 meldingnr">
              <Translate id="meldinger.details.label.messageno" fallback="" /> {melding.lopeNummer}
            </h4>
            <dl>
              <div className="row">
                <dt>
                  <FaIcon
                    icon="map-marked-alt"
                    size="lg"
                    title={formatMessage({ id: 'meldingsdetaljer.locationIconTitle' })}
                  />
                </dt>
                <dd>{melding.adresse}</dd>
              </div>
              <div className="row">
                <dt>
                  <FaIcon icon="tag" size="lg" title={formatMessage({ id: 'meldingsdetaljer.tyoe' })} />
                </dt>
                <dd>{melding.meldingstype?.beskrivelse}</dd>
              </div>
              <div className="row">
                <dt>
                  <FaIcon
                    icon="calendar-alt"
                    size="lg"
                    title={formatMessage({ id: 'meldingsdetaljer.date_created' })}
                  />
                </dt>
                <dd>
                  <FormatDate date={melding.opprettetTid} />
                </dd>
              </div>
              {melding?.beskrivelse && (
                <div className="row">
                  <dt>
                    <FaIcon icon="pen" size="lg" title={formatMessage({ id: 'meldingsdetaljer.description' })} />
                  </dt>
                  <dd>{melding.beskrivelse}</dd>
                </div>
              )}
              {melding?.bildeIds?.length > 0 && (
                <div className="row">
                  <dt>
                    <FaIcon icon="camera" size="lg" title={formatMessage({ id: 'meldingsdetaljer.imgs' })} />
                  </dt>
                  <dd>
                    {melding.bildeIds &&
                      melding.bildeIds.map((id: string) => (
                        <img key={id} src={`${urls.imageUrl(id)}/thumb`} alt="bilde" />
                      ))}
                  </dd>
                </div>
              )}
            </dl>

            <div className="status">
              <div className="status-badge">{getStatus(melding.meldingsStatus)}</div>
              <div className="status-text">
                {getStatusText(melding.meldingsStatus.id, melding.meldingsStatus.tekstmal)}
              </div>
            </div>
          </div>
        </div>
      )}
      {error && <MeldingError />}
    </>
  );
};

export default Meldingsdetaljer;

export const MeldingsdetaljerTexts = {
  no: {
    'meldingsdetaljer.locationIconTitle': 'Lokasjon',
    'meldingsdetaljer.tyoe': 'Kategori',
    'meldingsdetaljer.date_created': 'Opprettet tidspunkt',
    'meldingsdetaljer.description': 'Beskrivelse',
    'meldingsdetaljer.imgs': 'Bilder',
  },
  en: {
    'meldingsdetaljer.locationIconTitle': 'Location',
    'meldingsdetaljer.tyoe': 'Type',
    'meldingsdetaljer.date_created': 'Time created',
    'meldingsdetaljer.description': 'Description',
    'meldingsdetaljer.imgs': 'Images',
  },
};
