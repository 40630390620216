export interface ValidationInterface {
  isValid: boolean;
  errormessageId: string;
}

export const validateUsername = (username: string): ValidationInterface => {
  return {
    isValid: username !== '',
    errormessageId: !username ? 'validatation.required' : '',
  };
};

export const validatePassword = (password: string): ValidationInterface => {
  return {
    isValid: password !== '',
    errormessageId: !password ? 'validatation.required' : '',
  };
};

export const validatePasswordMatch = (password: string, rePassword: string): ValidationInterface => {
  const isValid = password === rePassword;
  return {
    isValid,
    errormessageId: isValid ? 'validatation.password-must-match' : '',
  };
};

export const passwordRegex = /^(?=.*[a-zæøå])(?=.*[A-ZÆØÅ])(?=.*\d)(?=.{8,})/;
