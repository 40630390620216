/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import Translate from '../locale/Translate';
import './RegistrerBruker.scss';
import RegistrerForm from './RegistrerForm';
import RegisteredUserSuccessPage from './RegisteredUserSuccessPage';
import Modal, { ModalSection } from '../common/modal/Modal';
import OutlineButton from '../common/button/OutlineButton';
import { useUserActions } from './UserActionProvider';

const RegistrerBruker = (): JSX.Element => {
  const { toggleLogin, toggleCreateUser, createUserOpen } = useUserActions();
  const [userWaitForActivation, setUserWaitForActivation] = useState({ username: '', password: '' });

  useEffect(() => {
    setUserWaitForActivation({ username: '', password: '' });
  }, [createUserOpen]);

  return (
    <>
      <Modal isOpen={createUserOpen} toggle={toggleCreateUser} preventScroll>
        <ModalSection>
          {!userWaitForActivation.username ? (
            <>
              <h1 className="heading-1 my-4">
                <Translate id="registration.pageHeader" />
              </h1>
              <div className="w-full my-3">
                <h2 className="heading-4 tablet:heading-3 pb-2">
                  <Translate id="registration.top-info-header" />
                </h2>
                <ul className="list-disc px-4">
                  <li className="text-3 tablet:text-2">
                    <Translate id="registration.top-info-1" />
                  </li>
                  <li className="text-3 tablet:text-2">
                    <Translate id="registration.top-info-2" />
                  </li>
                  <li className="text-3 tablet:text-2">
                    <Translate id="registration.top-info-3" />
                  </li>
                </ul>
              </div>
              {createUserOpen && <RegistrerForm onRegisterSuccess={(user) => setUserWaitForActivation(user)} />}
              <OutlineButton onClick={toggleLogin} className="w-full text-5 mt-3">
                <Translate id="registration.login-link" />
              </OutlineButton>
            </>
          ) : (
            <>
              <RegisteredUserSuccessPage user={userWaitForActivation} />
            </>
          )}
        </ModalSection>
      </Modal>
    </>
  );
};

export default RegistrerBruker;
