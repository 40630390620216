/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import Translate from '../locale/Translate';
import useInterval from '../../utlis/UseInterval';
import { useApi } from '../../api/apiProvider';
import { useUser } from './UserProvider';
import { SERVICE_ID } from '../../api/serviceInfo';
import { useUserActions } from './UserActionProvider';

interface RegisteredUserSuccessPageProps {
  user: { username: string; password: string };
}

const RegisteredUserSuccessPage = (props: RegisteredUserSuccessPageProps): JSX.Element => {
  const { user } = props;

  const [loading, setLoading] = useState(false);

  // const { state } = useLocation<LocationState>();
  const { urls, api } = useApi();
  const { loginUser } = useUser();
  const { toggleCreateUser } = useUserActions();

  const loginIfActivated = async (): Promise<void> => {
    if (!user.username || !user.password) return;
    setLoading(true);
    try {
      const url = urls.login();
      const res = await api.post(url, {
        epost: user?.username,
        passord: user?.password,
        serviceId: SERVICE_ID,
      });
      const token = res.data.result.access_token;
      loginUser(token);
      toggleCreateUser();
      setLoading(false);
    } catch (e) {
      //console.log('e', e);
    }
  };

  useInterval(() => {
    loginIfActivated();
  }, 5000);

  useEffect(() => {
    loginIfActivated();
  }, []);

  return (
    <>
      <h1 className="heading-1 m-2">
        <Translate id="confirmEmailPage.heading" fallback="Nesten ferdig..." />
      </h1>
      {loading && (
        <div className="whitespace-pre-line p-3 flex flex-col bg-state-success">
          <Translate
            id="confirmEmailPage.loginWhenActivatedMessage"
            values={{
              h: (chunks: any[]) => <h3 className="heading-3">{chunks}</h3>,
              b: (chunks: any[]) => <h3 className="text-5">{chunks}</h3>,
            }}
          />
        </div>
      )}

      <p className="m-2 mb-5">
        <Translate id="confirmEmailPage.info.completeregistration" fallback="" />
        {user?.username ? (
          <>
            : <b>{user.username}</b>.
          </>
        ) : (
          '.'
        )}
      </p>
      <p className="m-2">
        <Translate id="confirmEmailPage.info.notreceivingemail" fallback="" />
        <b className="font-bold">noreplyIKTdrift@bym.oslo.kommune.no</b>
      </p>
      <p className="m-2">
        <Translate
          id="confirmEmailPage.info.experienceproblems"
          fallback=""
          values={{
            chatLink: (chunks: any[]) => (
              <a
                className=" font-bold text-blue-dark "
                target="_blank"
                href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten"
                rel="noopener noreferrer"
                tabIndex={0}
              >
                {chunks}
              </a>
            ),
          }}
        />
      </p>
    </>
  );
};

export default RegisteredUserSuccessPage;
