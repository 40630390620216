import React from 'react';
import TextButton from '../common/button/TextButton';
import OutlineButton from '../common/button/OutlineButton';
import Translate from '../locale/Translate';

import LoginForm from './LoginForm';
import './Login.scss';
import Modal, { ModalSection } from '../common/modal/Modal';
import { useUserActions } from './UserActionProvider';

const Login = (): JSX.Element => {
  const { toggleLogin, toggleCreateUser, loginOpen, toggleGlemtPassord } = useUserActions();
  return (
    <Modal toggle={toggleLogin} isOpen={loginOpen} preventScroll>
      <ModalSection>
        <>
          <h1 className="heading-1 my-4">
            <Translate id="user.header" />
          </h1>
          {loginOpen && <LoginForm />}

          <OutlineButton className="w-full" onClick={toggleCreateUser}>
            <Translate id="user.opprett-bruker-link" fallback="Opprett bruker" />
          </OutlineButton>
          <div className="my-3">
            <TextButton className="mx-auto text-5" onClick={toggleGlemtPassord}>
              <Translate id="user.form-forgot-pw-link" fallback="Glemt passord" />
            </TextButton>
          </div>
        </>
      </ModalSection>
    </Modal>
  );
};

export default Login;
