import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { Itoken } from '../interfaces/userInterface';
import { SERVICE_ID } from '../api/serviceInfo';
import { login } from '../api/api';

const COOKIE_KEY = 'bymelding_portal_token';

export const getCachedToken = (): string | void => {
  const cookie = Cookies.get(COOKIE_KEY);
  return cookie;
};

export const setCachedToken = (token: string, expirationTime: number): void => {
  Cookies.set(COOKIE_KEY, token, {
    path: '/',
    expires: new Date(expirationTime * 1000),
  });
};

export const deleteToken = (): void => Cookies.remove(COOKIE_KEY, { path: '/' });

export const decodeToken = (jwt: string): Itoken | null => {
  try {
    return jwtDecode(jwt);
  } catch (err) {
    return null;
  }
};

export const getAnonympublikumToken = async (): Promise<string> => {
  const loginUrl = login();
  const res = await axios.post(loginUrl, {
    epost: 'anonympublikum@bym.no',
    passord: 'bymelding',
    serviceId: SERVICE_ID,
  });
  return res.data.result.access_token;
};

export const getAnonympublikumHeader = async (): Promise<{ headers: { Authorization: string } }> => {
  const token = await getAnonympublikumToken();
  const headerWithToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return headerWithToken;
};
