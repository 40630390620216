import React, { createContext, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router';
import Login from './Login';
import RegistrerBruker from './RegistrerBruker';
import GlemtPassord from './GlemtPassord';
import { useUser } from './UserProvider';

interface UserActionContext {
  toggleLogin: { (): void };
  toggleCreateUser: { (): void };
  toggleGlemtPassord: { (): void };
  loginOpen: boolean;
  createUserOpen: boolean;
  glemtPassordOpen: boolean;
}

export const UserActionContext = createContext({} as UserActionContext);

const UserActionProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;
  const { user } = useUser();

  const location = useLocation();
  const navigate = useNavigate();

  const userActionRouterState = {
    login: false,
    register: false,
    forgotPw: false,
  };

  const toggleLogin = (): void => {
    if (location.state?.login) navigate(`${location.pathname}`, { state: { ...userActionRouterState } });
    else navigate(`${location.pathname}`, { state: { ...userActionRouterState, login: true } });
  };

  const toggleCreateUser = (): void => {
    if (location.state?.register) navigate(`${location.pathname}`, { state: { ...userActionRouterState } });
    else navigate(`${location.pathname}`, { state: { ...userActionRouterState, register: true } });
  };

  const toggleGlemtPassord = (): void => {
    if (location.state?.forgotPw) navigate(`${location.pathname}`, { state: { ...userActionRouterState } });
    else navigate(`${location.pathname}`, { state: { ...userActionRouterState, forgotPw: true } });
  };

  return (
    <UserActionContext.Provider
      value={{
        toggleLogin,
        loginOpen: location.state?.login,
        toggleCreateUser,
        createUserOpen: location.state?.register,
        toggleGlemtPassord,
        glemtPassordOpen: location.state?.forgotPw,
      }}
    >
      {children}
      {!user.loggedIn && (
        <>
          {location.state?.login && <Login />}
          {location.state?.register && <RegistrerBruker />}
          {location.state?.forgotPw && <GlemtPassord />}
        </>
      )}
    </UserActionContext.Provider>
  );
};

export default UserActionProvider;

export const useUserActions = (): UserActionContext => useContext(UserActionContext);
