/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useEffect, useMemo, useReducer, useContext } from 'react';
import { useNavigate } from 'react-router';
import { samtykkeReducer, initState, UPDATE_STATE } from './SamtykkeReducer';
import Modal from '../common/modal/Modal';

import VilkaarText from './VilkaarText';

import { useUser } from '../user/UserProvider';
import { useApi } from '../../api/apiProvider';
import { useLocale } from '../locale/LocaleProvider';
import * as urls from '../../api/api';
import { CLIENT_VILKAAR_ID } from '../../api/serviceInfo';
import Translate from '../locale/Translate';
import Button from '../common/button/Button';
import OutlineButton from '../common/button/OutlineButton';

interface SamtykkeContext {
  rejectSamtykke: { (samtykkeId: string): void };
  acceptSamtykke: { (vilkaarId: string, brukerId: string): void };
  vilkaar: any;
  samtykke: any;
  userHasAcceptedTerms: boolean;
}

export const SamtykkeContext = createContext({} as SamtykkeContext);

const SamtykkeProvider = (props: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const { children } = props;
  const { user, logoutUser } = useUser();
  const { api } = useApi();
  const {
    currentLocale: { locale },
    localeInitialized,
  } = useLocale();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(samtykkeReducer, initState);

  const updateState = (payload: any): void => {
    dispatch({ type: UPDATE_STATE, payload });
  };

  const {
    samtykkeInitialized,
    samtykke,
    vilkaar,
    acceptedSamtykke,
    loadingVilkaar,
    loadingSamtykke,
    showConfirmReject,
  } = state;

  const userHasAcceptedTerms = useMemo(() => samtykkeInitialized && user.loggedIn && acceptedSamtykke, [
    user.loggedIn,
    samtykkeInitialized,
    acceptedSamtykke,
  ]);

  const rejectSamtykke = async (samtykkeId: string): Promise<void> => {
    const url = urls.slettSamtykke(CLIENT_VILKAAR_ID, samtykkeId);
    try {
      await api.delete(url);
      logoutUser();
      navigate('/');
    } catch (e) {
      // console.log('rejectSamtykke -> e', e);
    }
  };

  const acceptSamtykke = async (vilkaarId: string, brukerId: string): Promise<void> => {
    const url = urls.postSamtykke(CLIENT_VILKAAR_ID);
    try {
      await api.post(url, { vilkaarId, brukerId });
      updateState({
        samtykkeInitialized: false,
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (localeInitialized) {
      const l = locale === 'nb' ? 'nb-NO' : 'en-US';
      const url = urls.getVilkaar(CLIENT_VILKAAR_ID, l);
      updateState({ loadingVilkaar: true });
      api
        .get(url)
        .then((res: { data: { result: any[] } }) => {
          dispatch({ type: UPDATE_STATE, payload: { vilkaar: res.data.result[0] } });
        })
        .catch(() => {
          // Catcher ikke hvis det ikke er en arrow function her.
        })
        .finally(() => {
          updateState({ loadingVilkaar: false });
        });
    }
  }, [api, locale, localeInitialized]);

  useEffect(() => {
    if (user.loggedIn && user.id && vilkaar) {
      const url = urls.getSamtykke(CLIENT_VILKAAR_ID, user.id);
      updateState({ loadingSamtykke: true });
      api
        .get(url)
        .then((res: { data: { result: any[] } }) => {
          const s = res.data.result[0];
          dispatch({ type: UPDATE_STATE, payload: { samtykke: s } });
        })
        .catch(() => {
          // Catcher ikke hvis det ikke er en arrow function her.
        })
        .finally(() => {
          updateState({ loadingSamtykke: false });
        });
    }
  }, [api, user.id, user.loggedIn, vilkaar, samtykkeInitialized]);

  /**
   * If both samtykke and vilkaar is present, check if user has accepted the newest vilkkaar
   */
  useEffect(() => {
    if (!loadingVilkaar && !loadingSamtykke) {
      if (vilkaar?.id === samtykke?.vilkaarId)
        dispatch({ type: UPDATE_STATE, payload: { acceptedSamtykke: true, samtykkeInitialized: true } });
      else {
        dispatch({ type: UPDATE_STATE, payload: { acceptedSamtykke: false, samtykkeInitialized: true } });
      }
    }
  }, [samtykke, vilkaar, loadingVilkaar, loadingSamtykke]);

  return (
    <>
      <SamtykkeContext.Provider value={{ rejectSamtykke, acceptSamtykke, samtykke, vilkaar, userHasAcceptedTerms }}>
        {vilkaar && (
          <Modal
            closeButton={false}
            isOpen={
              user.loggedIn && !userHasAcceptedTerms && !showConfirmReject && samtykkeInitialized && !loadingSamtykke
            }
          >
            <h1 className="heading-2 pt-5">
              <Translate id="requestSamtyke.heading" />
            </h1>
            <p className="whitespace-pre-wrap py-3 mb-3" style={{ borderBottom: 'solid 1px gray' }}>
              <Translate id="requestSamtyke.body" />
            </p>
            <VilkaarText vilkaar={vilkaar?.vilkaarTekster[0]} />
            <div className="flex flex-col">
              <Button className="mt-3" colorOption="green-light" onClick={() => acceptSamtykke(vilkaar.id, user.id)}>
                <Translate id="samtykke.button.consent" />
              </Button>
              <Button className="mt-3" colorOption="red" onClick={() => updateState({ showConfirmReject: true })}>
                <Translate id="samtykke.button.decline" />
              </Button>
            </div>
          </Modal>
        )}
        <Modal isOpen={showConfirmReject} toggle={() => updateState({ showConfirmReject: !showConfirmReject })}>
          <h1 className="heading-2 pt-5">
            <Translate id="samtykke.declinetermsdialogue.heading" />
          </h1>
          <p className="whitespace-pre-wrap py-3 mb-3">
            <Translate id="samtykke.declinetermsdialogue.body" />
          </p>
          <div className="flex flex-col">
            <Button
              className="mt-3"
              colorOption="red"
              onClick={() => {
                logoutUser();
                updateState({
                  samtykkeInitialized: false,
                  acceptedSamtykke: false,
                  showConfirmReject: false,
                  samtykke: undefined,
                });
              }}
            >
              <Translate id="samtykke.button.decline" />
            </Button>
            <OutlineButton className="mt-3" onClick={() => updateState({ showConfirmReject: false })}>
              <Translate id="samtykke.button.cancel" />
            </OutlineButton>
          </div>
        </Modal>
        {children}
      </SamtykkeContext.Provider>
    </>
  );
};

export default SamtykkeProvider;

export const useSamtykke = (): SamtykkeContext => useContext(SamtykkeContext);
