import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import Inputfield from '../common/forms/Inputfield';
import Spinner from '../common/spinner/Spinner';
import Button from '../common/button/Button';
import Translate from '../locale/Translate';
import { useApi } from '../../api/apiProvider';
import { useUser } from './UserProvider';
import { SERVICE_ID } from '../../api/serviceInfo';
import { useUserActions } from './UserActionProvider';
import { useYupValidationResolver } from './useYupValidationResolver';

const LoginForm = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const { urls, api } = useApi();
  const { loginUser } = useUser();
  const { toggleLogin } = useUserActions();

  const loginUserSchema = useMemo(
    () =>
      yup.object({
        username: yup.string().lowercase().required('validatation.required').email('validatation.notavalidemail'),
        password: yup.string().required('validatation.required'),
      }),
    []
  );

  const resolver = useYupValidationResolver(loginUserSchema);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<{ username: string; password: string }>({
    mode: 'onSubmit',
    resolver,
  });

  const onUserLogin = handleSubmit(
    async ({ username, password }): Promise<void> => {
      setLoading(true);
      try {
        const url = urls.login();
        const res = await api.post(url, {
          epost: username,
          passord: password,
          serviceId: SERVICE_ID,
        });
        const token = res.data.result.access_token;
        loginUser(token);
        toggleLogin();
      } catch (e: any) {
        if (e?.response?.data?.errorMessage?.includes('E-post eller passord er ikke riktig'))
          setError('username', {
            message: 'validatation.notValidUser',
            type: 'conflict',
          });
      } finally {
        setLoading(false);
      }
    }
  );

  return (
    <div>
      <form className="flex flex-col w-full" id="login_user_form" name="login_user_form" onSubmit={onUserLogin}>
        <Inputfield
          id="username"
          autoFocus
          label={formatMessage({
            id: 'user.form-email',
            defaultMessage: 'Din E-post',
          })}
          error={
            errors.username &&
            formatMessage({
              id: errors.username.message?.toString(),
              defaultMessage: '',
            })
          }
          type="email"
          {...register('username')}
          required
        />
        <Inputfield
          id="password"
          type="password"
          label={formatMessage({
            id: 'user.form-password',
            defaultMessage: 'Passord',
          })}
          error={
            errors.password &&
            formatMessage({
              id: errors.password.message?.toString(),
              defaultMessage: '',
            })
          }
          {...register('password')}
          required
        />
        <Button
          className="mb-3"
          type="submit"
          // form="login_user_form"
          onClick={onUserLogin}
        >
          <Translate id="user.form-submit" fallback="Logg inn" />
        </Button>
      </form>
      {loading && <Spinner />}
    </div>
  );
};

export default LoginForm;
