import React, { useState } from 'react';
import { ReactComponent as Takk } from '../../images/takkSmilefjes.svg';
import Translate from '../locale/Translate';
import LinkButton from '../common/button/LinkButton';
import { useUser } from '../user/UserProvider';
import Modal, { ModalSection } from '../common/modal/Modal';
import TextButton from '../common/button/TextButton';

const InnmeldingsBekreftelse = (): JSX.Element => {
  const { user } = useUser();
  const ProcessFQA = (chunks: any[]): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <>
        <TextButton onClick={() => setIsModalOpen(!isModalOpen)}>{chunks}</TextButton>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            toggle={() => {
              setIsModalOpen(!isModalOpen);
            }}
            preventScroll
          >
            <ModalSection>
              <>
                <h3 className="heading-3 my-3">
                  <Translate id="innmelding-bekreft.faq.header" />
                </h3>
                <div className="w-full my-3 text-3 tablet:text-2">
                  <Translate id="innmelding-bekreft.faq.section1" />
                </div>
                <div className="w-full my-3 text-3 tablet:text-2">
                  <Translate id="innmelding-bekreft.faq.section2" />
                </div>
                <div className="w-full my-3 text-3 tablet:text-2">
                  <Translate id="innmelding-bekreft.faq.section3" />
                </div>
              </>
            </ModalSection>
          </Modal>
        )}
      </>
    );
  };
  return (
    <div className="flex flex-col align-middle flex-stretch justify-between innmeldingsbekreftelse">
      <Takk
        aria-label="Takk for at du gjør byen bedre."
        title="Takk for at du gjør byen bedre."
        className="w-full h-7 object-cover mt-6 mb-6 text-blue-dark"
      />
      <h1 className="heading-1 p-2 text-center">
        <Translate id="innmelding-bekreft.title" fallback="Takk for at du gjør byen bedre." />
      </h1>
      {user.loggedIn ? (
        <p className="text-1 p-2 text-center">
          <Translate
            id="innmelding-bekreft.text"
            values={{
              ProcessFQA: ProcessFQA,
            }}
          />
        </p>
      ) : (
        <p className="text-1 p-2 text-center">
          <Translate
            id="innmelding-bekreft.anon_text"
            values={{
              ProcessFQA: ProcessFQA,
            }}
          />
        </p>
      )}
      <div className="w-full flex flex-row flex-wrap justify-center mt-6">
        <LinkButton padding="3" className="w-1/4 m-3 mobile-only:w-2/3" to="/">
          <Translate id="innmelding-bekreft.newbutton" fallback="Ok" />
        </LinkButton>
        {user.loggedIn && (
          <LinkButton padding="3" colorOption="yellow" className="w-1/4 mobile-only:w-2/3 m-3" to="/meldinger">
            <Translate id="innmelding-bekreft.myMeldinger" />
          </LinkButton>
        )}
      </div>
    </div>
  );
};

export default InnmeldingsBekreftelse;

export const InnmeldingsBekreftelseTranslations = {
  no: {
    'innmelding-bekreft.title': 'Takk for at du gjør byen bedre!',
    'innmelding-bekreft.text':
      '<ProcessFQA>Hva skjer med meldingen din?</ProcessFQA> Du kan følge med på oppdateringer under «Mine meldinger» i menyen.',
    'mine-meldinger-faq.text': '<ProcessFQA>Hva skjer med meldingene dine?</ProcessFQA>',
    'innmelding-bekreft.faq.header': 'Hva skjer med en melding når den sendes inn?',
    'innmelding-bekreft.faq.section1':
      'Når du oppretter en bymelding går meldingen direkte til våre entreprenører, slik at de kan utbedre i henhold til kontraktene de har med Bymiljøetaten. Kategoriene og lokasjon på kartet sørger for at meldingen kommer frem til rett entreprenør.',
    'innmelding-bekreft.faq.section2':
      'Hvis du har opprettet en bruker i Bymelding får du tilgang til statusoppdateringer, oversikt over innmeldte saker og mulighet til å følge saker. Når en melding får status «Ferdig behandlet» sendes en kvitteringsmelding til innmelder om e-post er oppgitt, som bekrefter at meldingen er avsluttet.',
    'innmelding-bekreft.faq.section3':
      'Hvis saken er innenfor Bymiljøetaten sitt ansvarsområde betyr dette at arbeidet er utført, en utbedring er bestilt eller at meldingen er videreformidlet til etatens områdeansvarlig for vurdering. Meldingssystemet håndterer kun meldinger til Bymiljøetaten, ikke andre virksomheter i Oslo kommune eller andre offentlige instanser, som for eksempel Statens Vegvesen.',
    'innmelding-bekreft.anon_text':
      '<ProcessFQA>Hva skjer med meldingen din?</ProcessFQA> Din sak vil bli behandlet så raskt som mulig. En eventuell utbedring vil være synlig på stedet.',
    'innmelding-bekreft.newbutton': 'Ok',
    'innmelding-bekreft.myMeldinger': 'Mine meldinger',
    'innmelding-bekreft.followingMeldinger': 'Meldinger jeg følger',
  },
  en: {
    'innmelding-bekreft.title': 'Thank you for making this city a better place!',
    'innmelding-bekreft.text': 'You can see updates under "My Messages" in the menu.',
    'innmelding-bekreft.faq.header': 'Hva skjer med en melding når den sendes inn?',
    'innmelding-bekreft.faq.section1':
      'Når du oppretter en bymelding går meldingen direkte til våre entreprenører, slik at de kan utbedre i henhold til kontraktene de har med Bymiljøetaten. Kategoriene og lokasjon på kartet sørger for at meldingen kommer frem til rett entreprenør.',
    'innmelding-bekreft.faq.section2':
      'Hvis du har opprettet en bruker i Bymelding får du tilgang til statusoppdateringer, oversikt over innmeldte saker og mulighet til å følge saker. Når en melding får status «Ferdig behandlet» sendes en kvitteringsmelding til innmelder om e-post er oppgitt, som bekrefter at meldingen er avsluttet.',
    'innmelding-bekreft.faq.section3':
      'Hvis saken er innenfor Bymiljøetaten sitt ansvarsområde betyr dette at arbeidet er utført, en utbedring er bestilt eller at meldingen er videreformidlet til etatens områdeansvarlig for vurdering. Meldingssystemet håndterer kun meldinger til Bymiljøetaten, ikke andre virksomheter i Oslo kommune eller andre offentlige instanser, som for eksempel Statens Vegvesen.',
    'innmelding-bekreft.anon_text':
      'Your case will be dealt with as soon as possible. Any improvement will be visible on site',
    'innmelding-bekreft.newbutton': 'Ok',
    'innmelding-bekreft.myMeldinger': 'My messages',
    'innmelding-bekreft.followingMeldinger': 'Messages I follow',
  },
};
