/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import TextButton from '../common/button/TextButton';
import { SERVICE_ID, CLIENT_ID } from '../../api/serviceInfo';
import Button from '../common/button/Button';
import Spinner from '../common/spinner/Spinner';
import Inputfield from '../common/forms/Inputfield';
import Translate from '../locale/Translate';
import { useApi } from '../../api/apiProvider';
import { getAnonympublikumHeader } from '../../utlis/tokenUtils';
import { useUserActions } from './UserActionProvider';
import { useYupValidationResolver } from './useYupValidationResolver';

const GlemtPassordForm = (props: { onGlemtPwSuccess?: { (): void } }): JSX.Element => {
  const { onGlemtPwSuccess } = props;
  const glemtPassordSchema = useMemo(
    () =>
      yup.object({
        username: yup.string().lowercase().required('validatation.required').email('validatation.notavalidemail'),
      }),
    []
  );

  const resolver = useYupValidationResolver(glemtPassordSchema);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ username: string }>({
    mode: 'onSubmit',
    resolver,
  });

  const [loading, setLoading] = useState(false);
  const [unknownEmailError, setUnknownEmailError] = useState(false);
  const { urls, api } = useApi();
  const { formatMessage } = useIntl();
  const { toggleCreateUser } = useUserActions();

  const onGlemtPassord = handleSubmit(
    async ({ username }: { username: string }): Promise<void> => {
      setLoading(true);
      try {
        const url = urls.resetPassword();
        const headerWithToken = await getAnonympublikumHeader();
        await api.post(
          url,
          {
            epost: username,
            serviceId: SERVICE_ID,
            clientId: CLIENT_ID,
          },
          headerWithToken
        );
        if (onGlemtPwSuccess) onGlemtPwSuccess();
      } catch (e: any) {
        if (e?.response?.data.errorMessage.includes('Det finnes ikke bruker med epost')) setUnknownEmailError(true);
      } finally {
        setLoading(false);
      }
    }
  );

  return (
    <>
      <form className="flex flex-col w-full" id="forgot_pw_form" name="forgot_pw_form" onSubmit={onGlemtPassord}>
        {unknownEmailError && (
          <div className="whitespace-pre-line p-3 mb-3 text-4 bg-state-danger">
            <Translate
              id="forgotpw.form-error-unknown-email"
              values={{
                link: (chunks: any[]) => (
                  <TextButton onClick={toggleCreateUser} className="oslo-button p-0 bg-transparent border-0">
                    {chunks}
                  </TextButton>
                ),
              }}
            />
          </div>
        )}
        <Inputfield
          autoFocus
          label={formatMessage({
            id: 'forgotpw.form-email',
            defaultMessage: 'E-post',
          })}
          type="email"
          error={
            errors.username &&
            formatMessage({
              id: errors.username.message?.toString(),
              defaultMessage: '',
            })
          }
          // ref={register}
          required
          {...register('username')}
        />
        <Button className="mb-3" type="submit" form="forgot_pw_form" onClick={onGlemtPassord}>
          <Translate id="forgotpw.submit" fallback="" />
        </Button>
      </form>
      {loading && <Spinner />}
    </>
  );
};

export default GlemtPassordForm;
