import React, { useEffect } from 'react';
import Button from '../common/button/Button';
import Translate from '../locale/Translate';
import { useUser } from '../user/UserProvider';
import { useUserActions } from '../user/UserActionProvider';
import { Outlet } from 'react-router-dom';

const PrivateRoute = (): JSX.Element => {
  const { user } = useUser();
  const { toggleLogin, loginOpen } = useUserActions();

  useEffect(() => {
    if (!user.loggedIn && !loginOpen) toggleLogin();
  }, [loginOpen, toggleLogin, user.loggedIn]);

  return user.loggedIn ? (
    <Outlet />
  ) : (
    <div className="container py-3 text-1">
      {!loginOpen && (
        <>
          <h1 className="heading-1 text-center">
            <Translate id="private.header" />
          </h1>
          <p className=" text-center p-3">
            <Translate id="private.body" />
          </p>
          <div className="flex justify-center py-3">
            <Button className="m-1" onClick={toggleLogin}>
              <Translate id="private.login-btn" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PrivateRoute;

export const PrivateRouteTexts = {
  no: {
    'private.header': 'Hei!',
    'private.body': 'Du må være logget inn for å se dette innholdet.',
    'private.login-btn': 'Logg inn',
    'private.back-btn': 'Tilbake til startsiden',
  },
  en: {
    'private.header': 'Hi!',
    'private.body': 'You have to log in to see this content.',
    'private.login-btn': 'Logg inn',
    'private.back-btn': 'Go back to the start page',
  },
};
