import React, { createContext, useEffect, useState, useContext } from 'react';
import { isWithinInterval } from 'date-fns';
import { getCachedToken, setCachedToken, decodeToken, deleteToken } from '../../utlis/tokenUtils';
import { Iuser, Itoken } from '../../interfaces/userInterface';
import { useApi } from '../../api/apiProvider';

const initUser: Iuser = {
  id: '',
  name: '',
  email: '',
  primaryRole: '',
  roles: [],
  loggedIn: false,
};

interface UserContext {
  user: Iuser;
  loginUser: { (token: string): void };
  logoutUser: { (): void };
}

export const UserContext = createContext({} as UserContext);

const UserProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;
  const [user, setUser] = useState(initUser);

  const { setToken, removeTokenfromHeader } = useApi();

  const userFromToken = (token: Itoken): Iuser => {
    const roller = token.ProsessrolleRolleId ? JSON.parse(token.ProsessrolleRolleId) : [];
    return {
      id: token.brukerId,
      name: token.sub,
      email: token.email,
      primaryRole: token.ProsessrolleId,
      roles: roller,
      loggedIn: true,
    };
  };

  const loginUser = (token: string): void => {
    const decodedToken = decodeToken(token);
    setToken(token);
    if (decodedToken) {
      setCachedToken(token, decodedToken.exp);
      const u = userFromToken(decodedToken);
      setUser(u);
    }
  };

  const logoutUser = (): void => {
    setUser(initUser);
    removeTokenfromHeader();
    deleteToken();
  };

  useEffect((): void => {
    const token = getCachedToken();
    if (token) {
      const decodedToken = decodeToken(token);
      if (decodedToken) {
        const now = Date.now();
        const notBeforeTime = new Date(decodedToken.nbf * 1000);
        const expirationTime = new Date(decodedToken.exp * 1000);
        const expired = !isWithinInterval(now, {
          start: notBeforeTime,
          end: expirationTime,
        });
        if (expired) {
          deleteToken();
        } else {
          const currentUser = userFromToken(decodedToken);
          setUser(currentUser);
        }
      }
    }
  }, []);

  return <UserContext.Provider value={{ user, loginUser, logoutUser }}>{children}</UserContext.Provider>;
};

export default UserProvider;

export const useUser = (): UserContext => useContext(UserContext);
