import React, { useState } from 'react';
import Modal, { ModalSection } from '../common/modal/Modal';
import OutlineButton from '../common/button/OutlineButton';
import Translate from '../locale/Translate';
import GlemtPassordForm from './GlemtPassordForm';
import { useUserActions } from './UserActionProvider';

const GlemtPassord = (): JSX.Element => {
  const { toggleGlemtPassord, glemtPassordOpen, toggleLogin } = useUserActions();
  const [resetEmailSent, setResetEmailSent] = useState(false);
  return (
    <Modal
      toggle={() => {
        toggleGlemtPassord();
        setResetEmailSent(false);
      }}
      isOpen={glemtPassordOpen}
      preventScroll
    >
      <ModalSection>
        <h1 className="heading-1 my-4">
          <Translate id="forgotpw.pageHeader" />
        </h1>
        {resetEmailSent ? (
          <div className="whitespace-pre-line p-3 mb-4 text-3 border-state-success bg-state-success">
            <Translate id="forgotpw.pw-link-success" />
          </div>
        ) : (
          <>
            <p className="text-3 mb-4">
              <Translate id="forgotpw.pageBody" />
            </p>
            <GlemtPassordForm
              onGlemtPwSuccess={() => {
                setResetEmailSent(true);
              }}
            />
          </>
        )}
        <OutlineButton
          className="w-full mb-3"
          onClick={() => {
            toggleLogin();
            setResetEmailSent(false);
          }}
        >
          <Translate id="forgotpw.login-link" />
        </OutlineButton>
      </ModalSection>
    </Modal>
  );
};

export default GlemtPassord;
