import React, { useMemo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import LocalStoreUtils from '../../utlis/LocalStoreUtils';
import Translate from '../locale/Translate';
import Button from '../common/button/Button';
import OutlineButton from '../common/button/OutlineButton';
import { isProduction, isTest } from '../../utlis/environment';
import Icon from '../common/icons/Icon';
import { useNavigate } from 'react-router';

const CookiePopup = (): JSX.Element => {
  const cookiePopupStoreKey = 'cookie_popup_store_key';
  const [cookieShown, setCookieShown] = useState(false);
  const [initilized, setInitilized] = useState(false);
  const { getItem, setItem } = LocalStoreUtils();
  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const showCookiePopup = useMemo(() => initilized && !cookieShown, [cookieShown, initilized]);

  const initilize = async (): Promise<void> => {
    try {
      const shown = await getItem(cookiePopupStoreKey);
      setCookieShown(shown);
    } catch {
      setCookieShown(false);
    } finally {
      setInitilized(true);
    }
  };

  const confirmShown = async (): Promise<void> => {
    await setItem(cookiePopupStoreKey, true);
    setCookieShown(true);
  };

  const confirmAndReadMore = (): void => {
    confirmShown();
    navigate('/eula');
  };

  useEffect(() => {
    if (isProduction() || isTest()) initilize();
  }, []);

  return (
    <div className={`${showCookiePopup ? '' : 'hidden'}`}>
      <div className="modal-backdrop bg-black-backdrop" />
      <div className="modal-backdrop z-modalBackdrop">
        <div className="fixed bottom-0 modal z-modal w-full overflow-y-auto tablet:w-11/12 widescreen:w-8/12">
          <div className="w-100 p-5 mobile-only:p-3 b-0 flex bg-green flex-col text-blue-dark ">
            <div className="flex justify-between ">
              <h1 className="heading-1">
                <Translate id="cookiepopup.heading" />
              </h1>
              <OutlineButton padding="3" aria-label={formatMessage({ id: 'cookiepopup.close_button_label' })}>
                <Icon
                  icon="x"
                  aria-label={formatMessage({ id: 'cookiepopup.close_button_label' })}
                  onClick={confirmShown}
                />
              </OutlineButton>
            </div>

            <p className="text-2 my-4">
              <Translate id="cookiepopup.body" />
            </p>
            <div className="flex flex-start ">
              <OutlineButton className="mr-3" onClick={confirmAndReadMore}>
                <Translate id="cookiepopup.read_more" />
              </OutlineButton>
              <Button onClick={confirmShown}>
                <Translate id="cookiepopup.accept" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePopup;

export const cookiePopupTexts = {
  no: {
    'cookiepopup.heading': 'Dine data',
    'cookiepopup.close_button_label': 'Lukk',
    'cookiepopup.body':
      'For å gi deg en bedre opplevelse bruker Bymelding informasjonskapsler. Ved å bruke denne tjenesten godtar du vår bruk av informasjonskapsler.',
    'cookiepopup.read_more': 'Les mer',
    'cookiepopup.read_more_link': 'https://www.oslo.kommune.no/personvern-og-informasjonskapsler/',
    'cookiepopup.accept': 'Jeg forstår',
  },
  en: {
    'cookiepopup.heading': 'Your data',
    'cookiepopup.close_button_label': 'Close',
    'cookiepopup.body':
      'To give you a better experience, Bymelding uses cookies. By using this service you agree to our use of cookies.',
    'cookiepopup.read_more': 'Read more',
    'cookiepopup.read_more_link': 'https://www.oslo.kommune.no/privacy-and-cookies/',
    'cookiepopup.accept': 'I understand',
  },
};
